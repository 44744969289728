<template>
  <div class="navright">
    <div class="likeList">
      <div
        class="lItem"
        v-for="(item, i) in list"
        :key="i"
        @mouseover="item.imgShow = true"
        @mouseleave="item.imgShow = false"
      >
        <div class="ltop">
          <router-link
            :to="'/goods?guid=' + item.guid + '&discount=' + item.isDiscount"
          >
            <q-img
              :ratio="6 / 8"
              :src="
                item.imgShow
                  ? item.commoditypictures1.split(',')[1]
                  : item.commoditypictures1.split(',')[0]
              "
            />
          </router-link>
          <i
            v-show="!item.iswish"
            @click.stop="addWishBtn(item)"
            class="like iconfont icon-like"
          ></i>
          <i
            v-show="item.iswish"
            @click.stop="addWishBtn(item)"
            class="like iconfont icon-like_fill"
          ></i>
          <span
            class="loupe iconfont icon-sousuo"
            @click.stop="loupeHandle(item)"
          ></span>
        </div>
        <div class="lcenter">
          <div class="brand">{{ item.generalattributename }}</div>
          <div class="title no-wrap-txt">
            {{ $i18n.locale == "en" ? item.titleen : item.title }}
          </div>
          <div class="price line-through" v-if="item.isDiscount == 1">
            ￥{{ item.oPrice }}
          </div>
          <div v-if="item.isDiscount" class="price" style="color: #ac3948;">￥{{ item.Price }}</div>
          <div v-else class="price">￥{{ item.Price }}</div>
          <div class="size" v-if="item.size != '01'">{{ item.size }}</div>
        </div>
        <!-- 做预加载图片 -->
        <div class="onload-img">
          <img :src="item.commoditypictures1.split(',')[1]" alt />
        </div>
      </div>
      <m-empty v-if="!list.length" title="COMING SOON"></m-empty>
    </div>
    <div class="page">
      <span v-if="$i18n.locale == 'en'"
        >{{ params.pageindex }}/{{ totalCount }}</span
      >
      <span v-else
        >第{{ params.pageindex }}页/共{{ totalCount }}页</span
      >
      <q-btn
        color="primary"
        :label="$t('goods_list.more')"
        v-if="!finished"
        @click="pageHandle"
      />
    </div>
    <div class="page" style="margin-top: 50px">
      <q-pagination
        v-model="params.pageindex"
        :max="totalCount"
        direction-links
        :max-pages="7"
        @update:model-value="pageChange"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import { addwish } from '@/api/goods'
import { api as viewerApi } from 'v-viewer'
import mEmpty from '@/components/Empty.vue'

export default {
  components: {
    mEmpty
  },
  setup () {
    const store = useStore()
    const params = computed(() => store.state.goodsModule.params)
    const list = computed(() => store.state.goodsModule.list)
    const finished = computed(() => store.state.goodsModule.finished)
    const totalCount = computed(() => store.state.goodsModule.totalCount)
    // 收藏按钮
    const addWishBtn = async item => {
      const res = await addwish({
        mark: item.guid
      })
      if (res.status === 1) {
        item.iswish = true
      } else {
        item.iswish = false
      }
    }
    // 放大镜
    const loupeHandle = item => {
      viewerApi({
        images: item.commoditypictures1.split(',')
      })
    }
    // 分页
    const pageHandle = () => {
      params.value.pageindex++
    }
    // 切换分页
    const pageChange = () => {
      store.commit('pageChange')
    }
    return {
      params,
      list,
      addWishBtn,
      loupeHandle,
      pageHandle,
      pageChange,
      finished,
      totalCount
    }
  }
}
</script>

<style lang="scss" scoped>
.navright {
  position: relative;
  flex: 1;
  .likeList {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
    .lItem {
      position: relative;
      width: 25%;
      margin: 0;
      cursor: pointer;
      margin-bottom: 10px;
      box-sizing: border-box;
      border: 1px solid transparent;
      padding: 10px;
      &:hover {
        border-color: #d2d2d2;
      }
      .ltop {
        position: relative;
        padding-top: 30px;
        .like {
          position: absolute;
          right: 0px;
          top: 0px;
          font-size: 20px;
        }
        .loupe {
          position: absolute;
          left: 0px;
          top: 0px;
          font-size: 20px;
        }
        .left-size {
          min-width: 18px;
          display: none;
          flex-direction: column;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          background-color: #f4f4f4;
          font-size: 12px;
          padding: 0 1px;
          .size {
            text-align: center;
          }
        }
        &:hover {
          .left-size {
            display: flex;
          }
        }
      }
      .lcenter {
        width: 100%;
        overflow: hidden;
        text-align: center;
        margin-bottom: 10px;
        .brand {
          font-size: 12px;
          font-weight: bold;
          margin-top: 10px;
        }
        .price {
          font-weight: bold;
        }
        .size {
          word-wrap: break-word;
        }
      }
      .onload-img {
        position: absolute;
        left: 0;
        top: 0;
        height: 0;
        visibility: hidden;
        img {
          width: 0;
          height: 0;
        }
      }
      .lbottom {
        margin: 0 auto;
        margin-top: 20px;
        text-align: center;
        button {
          width: 190px;
          height: 46px;
          color: #fff;
          background-color: #000;
        }
        .graybtn {
          background-color: #e5e5e5;
          color: #a8a8a8;
        }
      }
    }
  }
  .page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
